:root {
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #a22020;
  --dl-color-danger-500: #bf2626;
  --dl-color-danger-700: #e14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #ffffff;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-darkmode-50: #faf9f7ff;
  --dl-color-primary-100: #003eb3;
  --dl-color-primary-300: #0074f0;
  --dl-color-primary-500: #14a9ff;
  --dl-color-primary-700: #85dcff;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32a94c;
  --dl-color-success-700: #4cc366;
  --dl-size-size-xxlarge: 288px;
  --dl-color-darkmode-100: #e8e6e1;
  --dl-color-darkmode-800: #423d33ff;
  --dl-color-darkmode-900: #27241dff;
  --dl-size-size-maxwidth: 1400px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
/* roboto-mono-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/roboto-mono-v22-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("/fonts/roboto-mono-v22-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/roboto-mono-v22-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("/fonts/roboto-mono-v22-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/fonts/roboto-mono-v22-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/roboto-mono-v22-latin-regular.svg#RobotoMono") format("svg"); /* Legacy iOS */
}
.button {
  color: var(--dl-color-gray-black);
  display: inline-block;
  padding: 0.5rem 1rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}

.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.menu-button {
  color: var(--dl-color-darkmode-100);
  font-size: 40px;
  margin-top: 2vh;
  transition-duration: 0.4s;
}
.menu-button:hover {
  color: #b8b2a7;
}
.centre {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-div {
  margin-top: 32px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.menu-container {
  display: flex;
  justify-content: space-between;
}
.menu-category {
  color: var(--dl-color-darkmode-100);
  font-size: 30px;
}
.menu-item {
  color: var(--dl-color-darkmode-100);
  margin-top: 16px;
  margin-left: 10px;
}
.terikatsu {
  font-size: 16px;
}
.menu-price {
  color: var(--dl-color-darkmode-100);
  margin-top: 16px;
  margin-left: 24px;
}
.menu-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.menu-items {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.menu {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.Content {
  font-size: 20px;
  font-family: Roboto Mono;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}
.Heading {
  font-size: 32px;
  font-family: Inter;
  font-weight: 700;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

.breakpoint {
  margin-left: 16px;
}
@media (max-width: 1500px) {
  .menu-column {
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media (max-width: 1200px) {
  .tempura {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .menu-column {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .menu-items {
    flex-direction: column;
  }
}
