.each-slide > div {
  display: flex;
  background-size: cover;
  height: 80vh;
  background-position: 50% 90%;
  max-width: 100%;
}
.each-slide {
  max-width: 100%;
}
.slide-container {
  width: 100%;
  height: 80vh;
}
.mobile-view {
  background-size: cover;
  display: flex;
  height: 80vh;
  background-position: 50% 50%;
  max-width: 100%;
}
