.navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 10vh;
}
.navbar-desktop-menu {
  width: 100%;
  display: flex;
  padding: 32px 48px;
  align-items: center;
}
.navbar-logo {
  max-width: 30vw;
  height: auto;
}
.navbar-mobile-logo {
  height: calc(10vh - 32px);
}
.navbar-nav {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.navbar-item {
  color: var(--dl-color-darkmode-100);
}
.navbar-mobile-menu {
  width: 100%;
  display: none;
  padding: 16px 32px;
  justify-content: space-between;
}
.navbar-logo {
  transition: 0.3s;
}
.navbar-logo:hover {
  transform: scale(1.05);
}
.navbar-icon {
  height: 100%;
}
@media (max-width: 991px) {
  .navbar-desktop-menu {
    display: none;
  }
  .navbar-mobile-menu {
    display: flex;
  }
}

@media (max-width: 767px) {
  .navbar-item {
    margin-left: var(--dl-space-space-unit);
  }
}
